<template>
  <div class="EditMaintenance">
    <router-link class="back-link"
                 :to="maintenanceRoute">
      <span class="ml-2">← Back to Maintenance</span>
    </router-link>
    <div class="row pl-2">
      <div class="col col-5 mr-5">
        <h2>Settings</h2>
        <ValidationObserver ref="observer">
          <form class="StatusPageForm"
                @submit.prevent="submit"
          >
            <base-textbox
              label="Name"
              ref="name"
              placeholder="Name"
              rules="max:255"
              v-model="maintenanceData.name"
              :custom-errors="errors['name']"
              @input="clearError('name')"
            />

            <base-textarea
              label="Description"
              ref="description"
              placeholder="Description"
              rows="5"
              v-model="maintenanceData.description"
              :custom-errors="errors['description']"
              @input="clearError('description')"
            />
            <div class="row">
              <base-date-picker
                placeholder="Starting date"
                label="Starting date"
                :config="datePickerConfig"
                v-model="date.starting"
                :value="date.starting"
                class="col col-6 date-picker"
                :custom-errors="errors['date.starting']"
                @input="clearError('date.starting')"
              />
              <base-date-picker
                placeholder="Starting time"
                label="Starting time"
                :config="timePickerConfig"
                v-model="time.starting"
                :value="time.starting"
                class="col col-6 time-picker"
                :custom-errors="errors['time.starting']"
                @input="clearError('time.starting')"
              />
            </div>
            <div class="row">
              <base-date-picker
                placeholder="Ending date"
                label="Ending date"
                :config="datePickerConfig"
                v-model="date.ending"
                :value="date.ending"
                class="col col-6 date-picker"
                :custom-errors="errors['date.ending']"
                @input="clearError('date.ending')"
              />
              <base-date-picker
                placeholder="Ending time"
                label="Ending time"
                :config="timePickerConfig"
                v-model="time.ending"
                :value="time.ending"
                class="col col-6 time-picker"
                :custom-errors="errors['time.ending']"
                @input="clearError('time.ending')"
              />
            </div>
            <div class="row g-0">
            <base-dropdown label="Timezone"
                           class="col timezone-picker"
                           v-model="selectedTimezone"
                           item-label="title"
                           track-by="abbr"
                           :options="timezones"
                           smallLabel
                           :custom-errors="errors['timezone']"
                           @input="clearError('timezone')"/>
            </div>

            <div class="row g-0">
              <base-dropdown label="Recurring"
                             class="col col-6 recurring-picker"
                             v-model="maintenanceData.recurring"
                             item-label="label"
                             track-by="value"
                             :options="recurringOptions"
                             smallLabel />
            </div>
            <div class="row toggle-container">
              <base-toggle
                v-model="maintenanceData.hideFromList"
                class="even-smaller cursor-pointer"
              />
              <p class="toggle-label">Hide from list of past events</p>
            </div>

            <div class="toolbar">
              <base-button
                type="submit"
                ref="submit"
                class="submit success mt-3"
                :loading="saving"
              >
                Save Changes
              </base-button>
            </div>

            <div v-show="false">
              <hint-limit-exceeded ref="limitExceeded"/>
            </div>

            <div v-if="error">
              <base-alert type="error">{{ error }}</base-alert>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="col col-6">
        <h2>Affected Websites</h2>
        <div class="card">
          <div class="card-body">
            <base-textbox-search
              class="search mb-4"
              v-model="search"
              placeholder="Search..."
              :bordered="true"
            />
            <base-alert v-if="!monitors.length" class="mb-2">No websites</base-alert>
            <div v-if="selectedMonitors">
              <WebsiteCard
                v-for="(element) in monitors"
                :key="element.id"
                :name="element.name"
                :url="element.url"
                :id="element.id"
                :selected="selectedMonitors.includes(element.id)"
                @updateWebsite="monitorSelect"
              />
            </div>
            <div class="error"  v-if="errors['monitors']">
              {{errors['monitors'][0]}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HintLimitExceeded from '@/components/Hints/HintStatusPagesLimitExceeded.vue'
import BaseAlert from '@/components/Base/BaseAlert.vue'
import { timezones } from '@/components/Account/Profile/ProfileUser/timezonesModel'
import { cloneObject } from '@/services/utils'
import tippy from 'tippy.js'
import { mapState } from 'vuex'
import BaseToggle from '@/components/Base/BaseToggle.vue'
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue'
import WebsiteCard from '@/components/StatusPage/Maintenance/WebsiteCard.vue'
import BaseTextboxSearch from '@/components/Base/BaseTextboxSearch.vue'
import statusPagesApi from '@/api/statusPagesApi'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export default {
  components: { BaseTextboxSearch, WebsiteCard, BaseDatePicker, BaseToggle, BaseAlert, HintLimitExceeded },
  metaInfo: {
    title: 'Edit Maintenance'
  },

  props: {
    statusPage: {
      required: true
    }
  },

  data () {
    return {
      maintenanceData: {
        name: '',
        description: '',
        recurring: { id: 1, label: 'Off', value: null }
      },
      recurringOptions: [
        { id: 1, label: 'Off', value: null },
        { id: 2, label: 'Daily', value: 'daily' },
        { id: 3, label: 'Weekly', value: 'weekly' },
        { id: 4, label: 'Monthly', value: 'monthly' }
      ],
      recurringFormated: null,
      excludeDowntime: true,
      hideFromList: false,
      selectedTimezone: null,
      timezones: null,
      profile: null,
      error: false,
      limitExceededTippy: null,
      updateSuccessTippy: null,
      saving: false,
      search: '',
      monitors: this.statusPage.monitors,
      datePickerConfig: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        closeOnSelect: true,
        defaultHour: 0
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K'
      },
      date: {
        starting: '',
        ending: ''
      },
      time: {
        starting: '',
        ending: ''
      },

      selectedMonitors: null,
      errors: []
    }
  },
  created () {
    this.profile = cloneObject(this.user)
    this.timezones = timezones
  },
  mounted () {
    if (this.$route.params.maintenanceId) {
      this.getMaintenanceData()
    } else {
      this.selectedMonitors = []
    }

    if (this.profile.timezone) {
      let data = JSON.parse(this.profile.timezone)
      this.selectedTimezone = this.timezones.find(item =>
        item.utc[0] === data['utc'] &&
        item.title === data['title']
      )
    }

    this.updateButtonTippy = tippy(this.$refs.submit.$el, {
      content: 'Maintenance was successfully saved',
      arrow: false,
      placement: 'right',
      trigger: 'manual'
    })
  },
  methods: {
    async submit () {
      // if (this.user.exceeded_status_pages_limit) {
      //   this.showLimitExceeded()
      //
      //   return false
      // }
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }
      this.saving = true
      try {
        const data = {
          name: this.maintenanceData.name,
          description: this.maintenanceData.description,
          timezone: this.selectedTimezone,
          monitors: this.selectedMonitors,
          date: this.date,
          time: this.time,
          hideFromList: this.maintenanceData.hideFromList,
          recurring: this.maintenanceData.recurring.value
        }

        if (this.$route.params.maintenanceId) {
          const response = await statusPagesApi.updateMaintenance(this.$route.params.maintenanceId, data)
          this.setMaintenanceDataFromResponse(response.data)
        } else {
          const response = await statusPagesApi.storeMaintenance(this.statusPage.id, data)
          this.$route.params.maintenanceId = response.data.id
        }

        this.error = false
        this.$emit('submitSuccess')
        this.showUpdateSuccess()
      } catch (error) {
        if (error.response.data.message.includes('domain')) {
          this.error = error.response.data.message
        } else if (error.response.data.errors) {
          console.log(error.response.data.errors)
          this.errors = error.response.data.errors
        } else {
          this.error = 'Something went wrong'
        }
      }
      this.saving = false
    },

    showLimitExceeded () {
      if (this.limitExceededTippy) {
        this.limitExceededTippy.destroy()
      }

      this.limitExceededTippy = tippy(this.$refs.submit.$el, {
        allowHTML: true,
        content: this.$refs.limitExceeded.$el,
        interactive: true,
        arrow: false,
        placement: 'top-start',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.limitExceededTippy.show()
    },

    monitorSelect (selected, id) {
      if (selected) {
        this.selectedMonitors.push(id)
      } else {
        this.selectedMonitors = this.selectedMonitors.filter(item => item !== id)
      }
      this.clearError('monitors')
    },

    // fetch maintenance by id
    async getMaintenanceData () {
      const response = await statusPagesApi.getMaintenanceItem(this.$route.params.maintenanceId)

      this.setMaintenanceDataFromResponse(response)
    },

    setMaintenanceDataFromResponse (data) {
      dayjs.extend(utc)

      this.maintenanceData.name = data.name
      this.maintenanceData.description = data.description
      this.maintenanceData.hideFromList = !!data.hide_from_list
      this.date.starting = dayjs.utc(data.start).format('YYYY-MM-DD')
      this.date.ending = dayjs.utc(data.end).format('YYYY-MM-DD')
      this.time.starting = dayjs.utc(data.start).format('H:mm').toString()
      this.time.ending = dayjs.utc(data.end).format('H:mm').toString()
      this.selectedTimezone = timezones.find(item =>
        item.value === JSON.parse(data.timezone)['value']
      )
      this.selectedMonitors = JSON.parse(data.monitors)

      this.maintenanceData.recurring = this.recurringOptions.find(item => item.value === data.recurring)
    },

    showUpdateSuccess () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    clearError (field) {
      delete this.errors[field]
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    maintenanceRoute () {
      return {
        name: 'statusPages.single.maintenance',
        params: {
          id: this.$route.params.id
        }
      }
    }
  },

  watch: {
    search (value) {
      this.monitors = this.statusPage.monitors.filter(monitor => {
        return monitor.url.toLowerCase().includes(value.toLowerCase())
      })
    }
  }
}
</script>

<style scoped lang="scss">
.EditMaintenance {

  .back-link {
    color: #000000;
    opacity: 0.4;
  }

  ::v-deep {
    .multiselect__single {
      padding-left: 20px !important;
    }
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-left: 2px;
    height: 50px;
  }

  .BaseTextboxSearch::v-deep {
    position: relative;

    .input {
      padding: 0 30px 0 35px;
    }

    .icon-search {
      position: absolute;
      left: 10px;
      top: 9px;
    }
  }

  .BaseToggle {
    &.toggle--size-small.even-smaller {
      width: 25px !important;
    }
  }

  .even-smaller::v-deep {
    margin: 0 5px 0 0;

    input[type="checkbox"] {
      ~ label .toggle__switch {
        height: 12px !important;
        max-width: 20px !important;

        &:after {
          height: 8px;
          width: 8px;
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
        }
      }

      &[disabled] ~ label {
        color: rgba(#777, 0.5);
      }
      &:focus ~ label, &:hover ~ label {
        .toggle__switch {
          background-color: #777;
          &:after {
            color: darken(#777, 10%);
          }
        }
      }
      &:hover ~label { color: darken(#777, 5%); }
      &:checked {
        ~ label {
          &:hover { color: darken(map-get($colors, purple-1), 3%); }

          .toggle__switch {
            background-color: map-get($colors, purple-1);
            &:after {
              color: darken(map-get($colors, purple-1), 5%);
              height: 8px;
              width: 8px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(10px, -50%) !important;
              transition: 0.3s;
            }
          }
        }

        &:focus, &:hover {
          ~ label {
            .toggle__switch {
              background-color: map-get($colors, purple-1);
              &:after {
                color: darken(map-get($colors, purple-1), 10%);
              }
            }
          }
        }
      }
    }

    label {
      .toggle__switch {
        transition: background-color 0.3s cubic-bezier(0,1,0.5,1);
        background: lighten(#777, 5%);
        font-weight: 600;
        &:before { color: rgba(white, 0.5) }
        &:after {
          transition: transform .25s,width .25s;
          transition-timing-function: cubic-bezier(.23,2.01,.43,.66);
          color: #777;
        }
      }
    }
  }

  .toggle-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0 20px 0 0;
  }

  .time-picker::v-deep {
    .flatpickr-input {
      padding: 21px 10px 21px 42px;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      border: 1px solid #e9ebec;
      border-radius: 6px;
      text-align: left;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      top: 45px;
      left: 27px;
      transform: translateY(-50%);
      min-width: 20px;
      height: 20px;
      background: url('../../../../../assets/images/clock.svg') no-repeat;
      pointer-events: none;
      z-index: 1;
    }
    .label {
      display: block;
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }
  }

  .recurring-picker::v-deep {
    .flatpickr-input {
      padding: 21px 10px 21px 42px;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      border: 1px solid #e9ebec;
      border-radius: 6px;
      text-align: left;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      top: 45px;
      left: 27px;
      transform: translateY(-50%);
      min-width: 20px;
      height: 20px;
      background: url('../../../../../assets/images/refresh-icon.svg') no-repeat;
      pointer-events: none;
      z-index: 1;
    }
    .label {
      display: block;
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }
  }

  .timezone-picker::v-deep {
    .flatpickr-input {
      padding: 21px 10px 21px 42px;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      border: 1px solid #e9ebec;
      border-radius: 6px;
      text-align: left;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      top: 45px;
      left: 27px;
      transform: translateY(-50%);
      min-width: 20px;
      height: 20px;
      background: url('../../../../../assets/images/timezone.svg') no-repeat;
      pointer-events: none;
      z-index: 1;
    }
    .label {
      display: block;
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }
  }

  .date-picker::v-deep {
    .form-control {
      padding: 21px 10px 21px 42px;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      border: 1px solid #e9ebec;
      border-radius: 6px;
      text-align: left;
    }

    &:before {
      content: '';
      position: absolute;
      top: 45px;
      left: 30px;
      transform: translateY(-50%);
      min-width: 20px;
      height: 20px;
      background: url('../../../../../assets/images/calendar.svg') no-repeat;
      pointer-events: none;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 40px;
      right: 25px;
      min-width: 11px;
      height: 8px;
      background: url('../../../../../assets/images/arrow_down_small.svg') no-repeat;
      pointer-events: none;
    }

    .label {
      display: block;
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }
  }

  .error {
    margin-bottom: 20px;
    margin-top: -12px;
    color: map-get($colors, red-2)
  }
}
</style>
